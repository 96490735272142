<template>
  <div class="container d-flex align-items-center justify-content-center fullpage">
    <b-card class="login-card" variant="dark" header="Primary" header-bg-variant="dark" header-text-variant="white" align="center">
      <template #header>
        <div class="d-flex align-items-center justify-content-center">
          <svg xmlns="http://www.w3.org/2000/svg" id="logo" width="100" height="70" viewBox="0 0 70 64">
            <path
              fill="#FFFFFF"
              d="M74.941 29.612a4.014 4.014 0 0 1-4.012 4.013c-2.216 0-3.988-1.797-3.988-4.013a4.012 4.012 0 0 1 8.026 0h-.026zm-4.037-7.458a7.442 7.442 0 0 0-7.458 7.409l-4.652 6.671c-.196-.025-.394-.025-.566-.025a5.646 5.646 0 0 0-2.855.788l-21.07-8.468a5.57 5.57 0 0 0-5.44-4.382 5.599 5.599 0 0 0-5.587 5.588 5.6 5.6 0 0 0 5.587 5.588 5.646 5.646 0 0 0 2.855-.788l21.07 8.468a5.57 5.57 0 0 0 5.44 4.382c2.88 0 5.269-2.215 5.538-5.021l7.164-5.243a7.453 7.453 0 0 0 7.458-7.458 7.453 7.453 0 0 0-7.458-7.458l-.026-.051zm0 2.461a5 5 0 0 1 4.997 4.997 5 5 0 0 1-4.997 4.997 5 5 0 0 1-4.996-4.997 5 5 0 0 1 4.996-4.997zm-42.067.985c1.575 0 2.929.886 3.619 2.166l-2.043-.812c-1.649-.591-3.471.221-4.11 1.846-.666 1.625.097 3.471 1.697 4.184l1.723.689a3.616 3.616 0 0 1-.886.099 4.103 4.103 0 0 1-4.111-4.111 4.103 4.103 0 0 1 4.111-4.111v.05zm29.366 12.062a4.101 4.101 0 0 1 4.111 4.11 4.102 4.102 0 0 1-4.111 4.11 4.055 4.055 0 0 1-3.619-2.19c.665.271 1.354.542 2.02.812a3.302 3.302 0 0 0 4.258-1.798 3.256 3.256 0 0 0-1.821-4.233l-1.698-.688c.295-.074.59-.1.886-.1l-.026-.023z"
            />
          </svg>
          <div class="logo-title">Login</div>
        </div>
      </template>
      <b-card-body>
        <b-row class="my-2">
          <b-col sm="3" class="d-flex align-items-center">
            <label for="username">Username:</label>
          </b-col>
          <b-col sm="9">
            <b-form-group :invalid-feedback="usernameFeedback">
              <b-form-input id="username" v-model="login.username" size="sm" :state="usernameState"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="my-2">
          <b-col sm="3" class="d-flex align-items-center">
            <label for="password">Password:</label>
          </b-col>
          <b-col sm="9">
            <b-form-group :invalid-feedback="passwordFeedback">
              <b-form-input type="password" id="password" v-model="login.password" size="sm" :state="passwordState"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-button size="sm" variant="dark" @click="handleSubmit"> Login <b-icon-arrow-right></b-icon-arrow-right></b-button>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import ToastService from "@/services/ToastService";
import SteamService from "../services/SteamService";
import { mapMutations } from "vuex";
export default {
  name: "Login",
  data() {
    return { login: {}, usernameFeedback: null, usernameState: null, passwordFeedback: null, passwordState: null };
  },

  created: function() {
    document.title = "Login";
  },

  mounted: function() {
    if (this.$route.query.exp == 1) {
      ToastService.errorToast("Session expired, login again!");
    }
    if (this.$route.query.fail == 1) {
      ToastService.errorToast("Wrong credentials, login again!");
    }
  },

  computed: {},

  methods: {
    ...mapMutations(["setUser", "setPass"]),
    checkFormValidity() {
      let formState = true;
      this.usernameState = true;
      this.passwordState = true;

      if (this.login.username == null) {
        this.usernameFeedback = "Username is required";
        this.usernameState = false;
        formState = false;
      }

      if (this.login.password == null) {
        this.passwordFeedback = "Password is required";
        this.passwordState = false;
        formState = false;
      }

      return formState;
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      } else {
        // Hide the modal manually
        this.$nextTick(() => {
          SteamService.login(this.login.username, this.login.password)
            .then((res) => {
              console.log("Login: ", res);
              if (res != "error") {
                this.$store.commit("setUser", this.login.username);
                this.$store.commit("setToken", res);
                this.$store.commit("setLogged", true);
                this.$router.push({ name: "Home" });
              } else ToastService.errorToast("Wrong credentials, try again!");
            })
            .catch((err) => {
              console.log(err);
              this.$router.push("/login?fail=1");
            });
        });
      }
    },
  },
};
</script>
<style scoped>
.container {
  margin-bottom: 5rem;
  height: 80vh;
}

.login-card {
  min-width: 450px;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-radius: 20px;
}

@media screen and (max-width: 600px) {
  .login-card {
    min-width: 150px;
  }
}

.card-header {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.logo-title {
  padding-left: 9px;
  font-size: 25px;
}
</style>
